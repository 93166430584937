var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-12 col-md-12" },
      [
        _c("q-table", {
          attrs: {
            title: "Elenco dei Simpli scartati",
            data: _vm.righe,
            columns: _vm.colonne,
            filter: _vm.filter,
            "row-key": "prodotto_id",
            "wrap-cells": "",
          },
          scopedSlots: _vm._u([
            {
              key: "top-right",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "panel-cerca" },
                    [
                      _c("q-input", {
                        staticStyle: { "min-width": "350px" },
                        attrs: {
                          outlined: "",
                          dense: "",
                          debounce: "300",
                          placeholder: "Cerca",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "append",
                            fn: function () {
                              return [
                                _c("q-icon", { attrs: { name: "search" } }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.filter,
                          callback: function ($$v) {
                            _vm.filter = $$v
                          },
                          expression: "filter",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "header",
              fn: function (props) {
                return [
                  _c(
                    "q-tr",
                    { attrs: { props: props } },
                    [
                      _c("q-th", { attrs: { "auto-width": "" } }),
                      _vm._l(props.cols, function (col) {
                        return _c(
                          "q-th",
                          { key: col.name, attrs: { props: props } },
                          [_vm._v(" " + _vm._s(col.label) + " ")]
                        )
                      }),
                      _c("q-th"),
                    ],
                    2
                  ),
                ]
              },
            },
            {
              key: "body",
              fn: function (props) {
                return [
                  _c(
                    "q-tr",
                    { attrs: { props: props } },
                    [
                      _c("q-td", { attrs: { "auto-width": "" } }),
                      _c("q-td", [_vm._v(_vm._s(props.cols[0].value))]),
                      _c("q-td", [_vm._v(_vm._s(props.cols[1].value))]),
                      _c("q-td", [_vm._v(_vm._s(props.cols[2].value))]),
                      _c("q-td", [_vm._v(_vm._s(props.cols[3].value))]),
                      _c("q-td", [_vm._v(_vm._s(props.cols[4].value))]),
                      _c("q-td", [_vm._v(_vm._s(props.cols[5].value))]),
                      _c("q-td", [_vm._v(_vm._s(props.cols[6].value))]),
                      _c(
                        "q-td",
                        [
                          _c("q-btn", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              color: "deep-orange",
                              glossy: "",
                              label: "INSERISCI",
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.onUploadSimpli(props.row)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }